// Locales
import 'dayjs/locale/vi'
import 'dayjs/locale/en'

import en from 'antd/lib/date-picker/locale/en_US'
import vi from 'antd/lib/date-picker/locale/vi_VN'
import dayjs from 'dayjs'

export const EN = 'en'
export const VI = 'vi'
export const COMMON = 'common'
export const CREATE = 'create'
export const UPDATE = 'update'
export const DETAIL = 'detail'
export const EDIT = 'edit'
export const CREATE_RETAIL_PO = 'create_retail_po'

export const CREATE_NEW = 'createNew'
export const PURCHASE_REQUEST = 'purchaseRequest'
export const VIGO_QUOTATION = 'vigoQuotation'
export const DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB = 'distributorPurchaseOrder'
export const PAYMENT_REQUEST_BREADCRUMB = 'paymentRequest'
export const SA_PAYOUT_STATEMENT_DETAIl = 'saPayoutStatementDetail'
export const UPDATE_WAREHOUSE_DETAILS = 'updateWarehouseDetails'
export const INBOUND = 'inbound'
export const INVENTORY = 'inventory'
export const ORDERS = 'orders'
export const PRE_ORDERS_TRANSLATE = 'preOrders'
export const TRADE_ORDERS_TRANSLATE = 'tradeOrders'
export const PICKLIST = 'picklist'
export const RIDERS = 'rider'
export const VEHICLE = 'vehicle'
export const DELIVERY = 'delivery'
export const PACKAGE = 'package'
export const REMITTANCE = 'remittance'
export const RIDER_REPORT = 'reportRider'
export const BUY_IN_INVOICE_KEY = 'buyInInvoice'
export const SELL_OUT_INVOICE_KEY = 'sellOutInvoice'
export const SUBSIDY = 'subsidy'
export const PO = 'po'
export const BIDDING_REQUEST_KEY = 'biddingRequest'
export const BIDDING_INVITATION_KEY = 'bi'
export const SUBMIT_BIDDING = 'submitBidding'
export const WAREHOUSE = 'warehouse'
export const BUDGET = 'budget'
export const DISTRIBUTOR_PROFILE_MANAGEMENT = 'distributorProfile'
export const SIGN_UP = 'signUp'
export const PRODUCT = 'product'
export const CONTRACT_FEE = 'contractFee'

export const LOCALE_LABEL = {
  [EN]: 'English',
  [VI]: 'Vietnamese',
}
export const LOCALE_FLAG = {
  [EN]: '/ukFlag.png',
  [VI]: '/vnFlag.png',
}

export type Translate = (key: string, opts?: Record<string, unknown>) => string

export const locale = (translate) =>
  translate('common.locale') === 'vi' ? vi : en

export const formatDateLLL = (value, translate) =>
  dayjs(value).locale(translate('common.locale')).format('LLL')
export const formatDate = (value, translate, type) =>
  dayjs(value).locale(translate('common.locale')).format(type)

export const formatDateA = (translate) =>
  `DD-MM-YYYY ${translate('common.locale') === 'en' ? 'hh:mm A' : 'HH:mm'}`

export const WELCOME_BACK = 'welcome'
