export const DISTRIBUTOR_TYPE_NORMAL = 'normal'
export const DISTRIBUTOR_TYPE_MASTER_DISTRIBUTOR_HUB = 'master_distributor_hub'

export const INDUSTRY_PHARMACY = 'pharmacy'
export const INDUSTRY_FMCG = 'fmcg'

export const INDUSTRY_OPTION = [
  { label: 'FMCG', value: 'fmcg' },
  { label: 'Pharmacy', value: 'pharmacy' },
]

export const STATUS_HISTORY_BANK_COLLECTION = [
  'bankCollectionName',
  'bankCollectionAccountNumber',
  'bankCollectionAccountName',
  'bankCollectionBranchCode',
]

export const STATUS_HISTORY_BANK_BUSINESS = [
  'bankName',
  'bankAccountNumber',
  'bankAccountName',
  'bankBranchCode',
]

export const STATUS_HISTORY_BILLING_ADDRESS = [
  'billingAddressCoordinateLat',
  'billingAddressCoordinateLong',
  'billingAddressDetail',
]

export const STATUS_HISTORY_SHIPPING_ADDRESS = [
  'shippingAddressCoordinateLat',
  'shippingAddressCoordinateLong',
  'shippingAddressDetail',
]
