export const PURCHASE_REQUEST_ALL = 'all'
export const PURCHASE_REQUEST_CREATED = 'created'
export const PURCHASE_REQUEST_QUOTING = 'quoting'
export const PURCHASE_REQUEST_QUOTED = 'quoted'
export const PURCHASE_REQUEST_CLOSED = 'closed'
export const PURCHASE_REQUEST_CANCELLED = 'cancelled'
export const PURCHASE_REQUEST_RECOMMENDED = 'recommended'
export const PURCHASE_REQUEST_CONFIRMED = 'confirmed'

export const CREATED_METHOD_NORMAL = 'normal'
export const CREATED_METHOD_BOT = 'bot'
export const IS_LAST_7_DAYS = 'isLast7Days'
export const IS_LAST_30_DAYS = 'isLast30Days'
export const IS_DATE_RANGE = 'isDateRange'

export const STATUS_ALLOW_GET_DATA_ANALYTICS = [
  PURCHASE_REQUEST_RECOMMENDED,
  PURCHASE_REQUEST_CREATED,
]

export const PRE_ORDER_YES = 'pre_order_yes'
export const PRE_ORDER_NO = 'pre_order_no'

export const PURCHASE_REQUEST_FILTER_DATE = [IS_LAST_7_DAYS, IS_LAST_30_DAYS]

export const IS_PRE_ORDER_SELECT = {
  [PRE_ORDER_YES]: 'Yes',
  [PRE_ORDER_NO]: 'No',
}

export const DATE_SELECT = {
  [IS_LAST_7_DAYS]: 'in last 7 days',
  [IS_LAST_30_DAYS]: 'in last 30 days',
}

export const PURCHASE_REQUEST_TAB_MAPPING = {
  [PURCHASE_REQUEST_ALL]: 'ALL',
  [PURCHASE_REQUEST_RECOMMENDED]: 'RECOMMENDED',
  [PURCHASE_REQUEST_CREATED]: 'CREATED',
  [PURCHASE_REQUEST_QUOTING]: 'QUOTING',
  [PURCHASE_REQUEST_QUOTED]: 'QUOTED',
  [PURCHASE_REQUEST_CLOSED]: 'CLOSED',
  [PURCHASE_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PURCHASE_REQUEST_STATUS_SELECT = {
  [PURCHASE_REQUEST_RECOMMENDED]: 'RECOMMENDED',
  [PURCHASE_REQUEST_CREATED]: 'CREATED',
  [PURCHASE_REQUEST_QUOTING]: 'QUOTING',
  [PURCHASE_REQUEST_QUOTED]: 'QUOTED',
  [PURCHASE_REQUEST_CLOSED]: 'CLOSED',
  [PURCHASE_REQUEST_CANCELLED]: 'CANCELLED',
  [PURCHASE_REQUEST_CONFIRMED]: 'CONFIRMED',
}

export const PURCHASE_REQUEST_STATUS_COLOR_MAPPING = {
  [PURCHASE_REQUEST_RECOMMENDED]: 'geekblue',
  [PURCHASE_REQUEST_CREATED]: 'blue',
  [PURCHASE_REQUEST_QUOTING]: 'warning',
  [PURCHASE_REQUEST_QUOTED]: 'green',
  [PURCHASE_REQUEST_CLOSED]: 'default',
  [PURCHASE_REQUEST_CANCELLED]: 'red',
  [PURCHASE_REQUEST_CONFIRMED]: 'green',
}

export const ALLOW_EDIT_PURCHASE_REQUEST_STATUS = [
  PURCHASE_REQUEST_CREATED,
  PURCHASE_REQUEST_RECOMMENDED,
]
