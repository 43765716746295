import { message } from 'antd'
import axios from 'axios'
import getConfig from 'next/config'
import { getSession } from 'next-auth/react'

const { publicRuntimeConfig } = getConfig()
const authRequired = publicRuntimeConfig.authRequired === 'true'

const defaultOptions = {
  baseURL: publicRuntimeConfig.gatewayApiURL,
  headers: {
    'Content-Type': 'application/json',
  },
}

const axiosAdminPortal = axios.create(defaultOptions)
// Set the AUTH token for any request
axiosAdminPortal.interceptors.request.use(async (config) => {
  let token = localStorage.getItem('accessToken')
  if (!token) {
    const session = await getSession()
    if (session?.accessToken) {
      token = session.accessToken as string
      localStorage.setItem('accessToken', token)
    }
  }
  config.headers.Authorization = authRequired && token ? `Bearer ${token}` : ''

  return config
})

axiosAdminPortal.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log({ error })
    if (error.response.status === 401) {
      message.error('Your session has expired!')
      // localStorage.removeItem('accessToken')
      // localStorage.setItem('temp-redirect', location.pathname)
      // router.push('/')
    } else {
      return Promise.reject(error)
    }
  }
)

export default axiosAdminPortal
